@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* Theme Color
Navbar
Theme Primary Background
Theme Primary Text
Theme Primary Border
Theme Primary Button
Icon Button
Custom Container
Home Banner
Gutter Gap
Star Rating
Scrollbar
Breadcrumbs
Ribbon
Carousel
Slider
Marquee
Card
As Per Requirements
Checkout Page
Deal
Single Blog
Chip
input-with-icon
Card Type
Card Cvv
Card No
Card Holder
Card Expiry
Custom Modal
OTP BOX 
 */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

/* Theme Color */
:root {
  --theme-primary: #32cd32;
  --theme-primary-light: #66ff66;
  --theme-primary-dark: #044e04;
  --theme-primary-shadow: #32cd3240;
  --white: #fff;
  --light: #f8f9fa;
  --light2: #f2f2f2;
  --light3: #e9ecef;
  --dark: #343a40;
  --dark2: #00000020;
  --secondary: #6c757d;
  --bg-theme-secondary: #fff5e1;
}

/* Navbar */
#navbarSupportedContent ul li .nav-link {
  font-size: 18px;
}
#navbarSupportedContent ul li .nav-link.active {
  color: var(--theme-primary);
  border-radius: 0.25rem;
  border-bottom: 2px solid var(--theme-primary);
}

/* Theme Primary Background */
.bg-light-2 {
  background-color: var(--light2);
}
.bg-theme-secondary {
  background-color: var(--bg-theme-secondary);
}
.bg-theme-primary {
  background-color: var(--theme-primary);
}
.bg-theme-primary-light {
  background-color: var(--theme-primary-light);
}
.bg-theme-primary-light-2 {
  background-color: var(--theme-primary-shadow);
}
.bg-theme-primary-dark {
  background-color: var(--theme-primary-dark);
}

/* Theme Primary Text */
.text-primary-theme {
  color: var(--theme-primary) !important;
}
.text-primary-light-theme {
  color: var(--theme-primary-light) !important;
}
.text-primary-dark-theme {
  color: var(--theme-primary-dark) !important;
}

/* Theme Primary Border */
.border-primary-theme {
  border-color: var(--theme-primary);
}
.border-primary-light-theme {
  border-color: var(--theme-primary-light);
}
.border-primary-dark-theme {
  border-color: var(--theme-primary-dark);
}

/* Theme Primary Button */
.theme-btn-primary {
  color: #fff;
  background-color: var(--theme-primary);
  transition: background-color 0.4s ease, transform 0.4s ease;
}
.theme-btn-primary:hover {
  color: #fff;
  background-color: var(--theme-primary-dark);
}
.theme-btn-primary:focus,
.theme-btn-primary.focus {
  color: #fff;
  background-color: var(--theme-primary-dark);
  box-shadow: 0 0 0 0.2rem rgba(50, 205, 50, 0.5);
}
.theme-btn-primary:active,
.theme-btn-primary.active,
.open > .dropdown-toggle.theme-btn-primary {
  color: #fff;
  background-color: var(--theme-primary-dark);
  border-color: var(--theme-primary-dark);
}
.theme-btn-primary:active:focus,
.theme-btn-primary.active:focus,
.theme-btn-primary:active.focus,
.theme-btn-primary.active.focus {
  box-shadow: 0 0 0 0.2rem rgba(50, 205, 50, 0.5);
}
.theme-btn-primary:disabled,
.theme-btn-primary.disabled {
  color: #fff;
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  opacity: 0.65;
}
.theme-btn-outline-primary {
  color: var(--theme-primary);
  background-color: transparent;
  border: 2px solid var(--theme-primary);
  transition: color 0.4s ease, background-color 0.4s ease;
}
.theme-btn-outline-primary:hover {
  color: #fff;
  background-color: var(--theme-primary);
}
.theme-btn-outline-primary:focus,
.theme-btn-outline-primary.focus {
  color: #fff;
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  box-shadow: 0 0 0 0.2rem rgba(50, 205, 50, 0.5);
}
.theme-btn-outline-primary:active,
.theme-btn-outline-primary.active,
.open > .dropdown-toggle.theme-btn-outline-primary {
  color: #fff;
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
}
.theme-btn-outline-primary:disabled,
.theme-btn-outline-primary.disabled {
  color: var(--theme-primary);
  background-color: transparent;
  border-color: var(--theme-primary);
  opacity: 0.65;
}
.theme-btn-outline-primary-dark {
  color: var(--theme-primary-dark);
  background-color: transparent;
  border: 2px solid var(--theme-primary-dark);
  transition: color 0.4s ease, background-color 0.4s ease;
}
.theme-btn-outline-primary-dark:hover {
  color: #fff;
  background-color: var(--theme-primary-dark);
}
.theme-btn-outline-primary-dark:focus,
.theme-btn-outline-primary-dark.focus {
  color: #fff;
  background-color: var(--theme-primary-dark);
  border-color: var(--theme-primary-dark);
  box-shadow: 0 0 0 0.2rem rgba(4, 78, 4, 0.5);
}
.theme-btn-outline-primary-dark:active,
.theme-btn-outline-primary-dark.active,
.open > .dropdown-toggle.theme-btn-outline-primary-dark {
  color: #fff;
  background-color: var(--theme-primary-dark);
  border-color: var(--theme-primary-dark);
}
.theme-btn-outline-primary-dark:disabled,
.theme-btn-outline-primary-dark.disabled {
  color: var(--theme-primary-dark);
  background-color: transparent;
  border-color: var(--theme-primary-dark);
  opacity: 0.65;
}
.custom-btn {
  padding: 0.8em 1.75em;
  background-color: transparent;
  border-radius: 6px;
  border: 0.3px solid var(--theme-primary);
  transition: 0.5s;
  font-size: 17px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  color: var(--theme-primary);
}

.custom-btn::after,
.custom-btn::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  transform: skew(90deg) translate(-50%, -50%);
  position: absolute;
  inset: 50%;
  left: 25%;
  z-index: -1;
  transition: 0.5s ease-out;
  background-color: var(--theme-primary);
}

.custom-btn::before {
  top: -50%;
  left: -25%;
  transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
}

.custom-btn:hover::before {
  transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
}

.custom-btn:hover::after {
  transform: skew(45deg) translate(-50%, -50%);
}

.custom-btn:hover {
  color: var(--white);
}

.custom-btn:active {
  filter: brightness(0.7);
  transform: scale(0.98);
}

/* Icon Button */
.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
}
.btn-icon svg {
  width: 20px;
  height: 20px;
}
.btn-icon-sm {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
}
.btn-icon-lg {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border: none;
}
.btn-icon-lg svg {
  width: 28px;
  height: 28px;
}
/* Custom Container */
.custom-container {
  width: 100%;
  padding: 0 50px;
  box-sizing: border-box;
}
@media (max-width: 992px) {
  .custom-container {
    padding: 0 34px;
  }
}
@media (max-width: 768px) {
  .custom-container {
    padding: 0 20px;
  }
}
@media (max-width: 576px) {
  .custom-container {
    padding: 0 1rem;
  }
}

/* Home Banner */
.home-banner {
  background: url("../Images/banner.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding-block: 6rem;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.banner-text {
  font-size: 54px;
  font-weight: 700;
  text-shadow: 0 0 1px var(--secondary);
}
@media (max-width: 576px) {
  .home-banner {
    padding-block: 4rem;
  }
  .banner-text {
    font-size: 34px;
  }
}

/* Gutter Gap */
.g-5,
.gx-5 {
  --bs-gutter-x: 2rem;
}
.g-5,
.gy-5 {
  --bs-gutter-y: 2rem;
}
.g-6,
.gx-6 {
  --bs-gutter-x: 2.5rem;
}
.g-6,
.gy-6 {
  --bs-gutter-y: 2.5rem;
}
.g-7,
.gx-7 {
  --bs-gutter-x: 3rem;
}
.g-7,
.gy-7 {
  --bs-gutter-y: 3rem;
}
@media (min-width: 576px) {
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 2rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 2rem;
  }

  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 3rem;
  }

  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 3rem;
  }
}

/* Star Rating */
.rating-group {
  display: inline-flex;
}

.rating__input {
  position: absolute !important;
  left: -9999px !important;
}

.rating__label {
  cursor: pointer;
  padding: 0 5px;
  font-size: 2rem;
}

.rating__icon {
  color: #ddd;
}

.rating__icon--star {
  color: orange;
}

.rating-group:hover .rating_label .rating_icon--star,
.rating_input:checked ~ .ratinglabel .rating_icon--star {
  color: orange;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: var(--dark2);
}
::-webkit-scrollbar-thumb {
  background: var(--theme-primary-shadow);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--theme-primary);
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Breadcrumbs */
.breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb-item {
  color: #fff;
  width: auto;
  position: relative;
  height: 40px;
  text-align: center;
  padding: 0 30px;
  font-size: 18px;
  padding-right: 35px;
  white-space: nowrap;
  border: none !important;
  outline: none !important;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.breadcrumb-item:hover {
  border: none !important;
  outline: none !important;
}
.breadcrumb-item:active {
  border: none !important;
  outline: none !important;
}

.breadcrumb-disabled {
  pointer-events: none;
}

.breadcrumb-item:nth-child(1) {
  background-color: var(--theme-primary);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.breadcrumb-item:nth-child(2) {
  background-color: #71ff71;
  padding-inline: 35px;
}

.breadcrumb-item:nth-child(3) {
  background-color: var(--theme-primary);
  padding-inline: 35px;
}

.breadcrumb-after {
  height: 32px;
  width: 32px;
  background-color: inherit;
  position: absolute;
  top: 50%;
  right: -16px;
  transform: translateY(-50%) rotate(45deg);
  z-index: 10;
  border-top: 2px solid var(--bg-theme-secondary);
  border-right: 2px solid var(--bg-theme-secondary);
}

.breadcrumb-item::before {
  content: "" !important;
}

@media screen and (max-width: 600px) {
  .breadcrumbs {
    justify-content: start;
    overflow: auto;
  }
  .breadcrumb-item {
    font-size: 14px;
    height: 45px;
  }
  .breadcrumb-after {
    height: 34px;
    width: 34px;
    right: -15px;
  }
}

/* Ribbon */
.ribbon-container {
  background: transparent !important;
  position: relative;
  z-index: 1;
}
.ribbon {
  background: hsl(30, 90%, 65%)
    url(http://portfolio.queenofwhisks.com/demo/overlay/noise.png);
  box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.5);
  font-family: Charter, serif;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  margin: 0 auto;
  padding: 0.25em 0;
  position: relative;
  width: 70%;
}
.ribbon:before,
.ribbon:after {
  border: 1.5em solid hsl(30, 82%, 62%);
  content: "";
  display: block;
  position: absolute;
  bottom: -1em;
  z-index: -1;
}
.ribbon:before {
  left: -2em;
  border-right-width: 1.5em;
  border-left-color: transparent;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}
.ribbon:after {
  right: -2em;
  border-left-width: 1.5em;
  border-right-color: transparent;
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.5);
}
.ribbon-content {
  border-top: 0.0625em dashed hsl(30, 90%, 44%);
  border-bottom: 0.0625em dashed hsl(30, 90%, 44%);
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.08),
    inset 0px -1px 1px rgba(255, 255, 255, 0.08),
    0px -1px 1px rgba(255, 255, 255, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.08);
  display: block;
  padding: 0.6875em 0;
}
.ribbon-content:before,
.ribbon-content:after {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-color: hsl(30, 80%, 40%) transparent transparent transparent;
  bottom: -1em;
}
.ribbon-content:before {
  left: 0;
  border-width: 1em 0 0 1em;
}
.ribbon-content:after {
  right: 0;
  border-width: 1em 1em 0 0;
}
.ribbon span {
  color: hsl(30, 90%, 40%);
  text-decoration: none;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25);
}
.ribbon span:hover {
  color: hsl(30, 90%, 43%);
}

/* Ribbon Theme Color */
/* .ribbon-container {
  background: transparent !important;
  position: relative;
  z-index: 1;
}
.ribbon {
  background: var(--theme-primary) 
    url(http://portfolio.queenofwhisks.com/demo/overlay/noise.png);
  box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.5);
  font-family: Charter, serif;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  margin: 0 auto;
  padding: 0.25em 0;
  position: relative;
  width: 70%;
}
.ribbon:before,
.ribbon:after {
  border: 1.5em solid var(--theme-primary-dark);
  content: "";
  display: block;
  position: absolute;
  bottom: -1em;
  z-index: -1;
}
.ribbon:before {
  left: -2em;
  border-right-width: 1.5em;
  border-left-color: transparent;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}
.ribbon:after {
  right: -2em;
  border-left-width: 1.5em;
  border-right-color: transparent;
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.5);
}
.ribbon-content {
  border-top: 0.0625em dashed var(--theme-primary-dark);
  border-bottom: 0.0625em dashed var(--theme-primary-dark);
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.08),
    inset 0px -1px 1px rgba(255, 255, 255, 0.08),
    0px -1px 1px rgba(255, 255, 255, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.08);
  display: block;
  padding: 0.6875em 0;
}
.ribbon-content:before,
.ribbon-content:after {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-color: var(--theme-primary-dark) transparent transparent transparent;
  bottom: -1em;
}
.ribbon-content:before {
  left: 0;
  border-width: 1em 0 0 1em;
}
.ribbon-content:after {
  right: 0;
  border-width: 1em 1em 0 0;
}
.ribbon span {
  color: var(--white);
  text-decoration: none;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25);
} */

/* Carousel */
.slick-prev:before {
  color: var(--theme-primary);
}
.slick-next:before {
  color: var(--theme-primary);
}
.slick-dots li.slick-active button:before {
  color: var(--theme-primary);
}

/* Slider */
.css-ltlhnc-MuiSlider-root {
  color: var(--theme-primary) !important;
}
.css-188mx6n-MuiSlider-root {
  color: var(--theme-primary) !important;
}
.css-1228x3t {
  color: var(--theme-primary) !important;
}


/* Marquee */
.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 12s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

/* Card */
@import url("https://fonts.googleapis.com/css2?family=josefin+Sans:wght@400;500;600;700&display=swap");

.custom-debit-card-card {
  max-width: 440px;
  width: 100%;
  color: #fff;
  cursor: pointer;
  perspective: 1000px;
  font-family: "Josefin Sans", sans-serif;
}
.custom-debit-card-front {
  width: 100%;
  background-image: linear-gradient(45deg, #0045c7, #ff2c7d);
  top: 0;
  left: 0;
  border-radius: 15px;
  overflow: hidden;
  z-index: 1;
  backface-visibility: hidden;
}
.custom-debit-card-map-img {
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
  z-index: -1;
}
.custom-debit-card-card-no {
  font-size: 28px;
  margin-top: 25px;
  text-align: center;
}
.custom-debit-card-card-holder {
  margin-top: 25px;
}
.custom-debit-card-bar {
  background: #222;
  margin-left: -30px;
  margin-right: -30px;
  height: 60px;
  margin-top: 10px;
}
.custom-debit-card-card-cvv {
  margin-top: 20px;
}
.custom-debit-card-card-cvv img {
  width: 100%;
  display: block;
  line-height: 0;
  object-fit: cover;
}
.custom-debit-card-card-cvv p {
  background: #fff;
  color: #000;
  font-size: 22px;
  padding: 3px 20px;
}
.custom-debit-card-back {
  transform: rotateY(180deg);
}
.custom-debit-card-card:hover .custom-debit-card-card-inner {
  transform: rotateY(-180deg);
}

/* As Per Requirements */
.d-none-mw-420p {
  display: none !important;
}
.d-block-mw-420p {
  display: block !important;
}
.w-responsive {
  width: 100% !important;
}

@media (max-width: 420px) {
  .d-none-mw-420p {
    display: block !important;
  }
  .d-block-mw-420p {
    display: none !important;
  }
  .w-responsive {
    width: 80% !important;
  }
}

li::marker {
  color: var(--theme-primary-dark);
}
.pointer {
  cursor: pointer;
}
.btn-rounded {
  border-radius: 38px;
}
.rounded-40p {
  border-radius: 40px;
}

.fs-sm {
  font-size: 0.75rem;
}
.fs-sm-2 {
  font-size: 0.85rem;
}
.fw-400 {
  font-weight: 400;
}
.mb-2rem {
  margin-bottom: 2rem;
}
.rounded-10p {
  border-radius: 10px;
}
.custom-container form {
  margin-top: -10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.custom-container form .trip-tab {
  list-style: none;
  display: flex;
  gap: 1rem;
}
.banner-form-input {
  border: 0;
  outline: 0;
  font-size: 20px;
  border-bottom: 1px solid var(--light);
  border-radius: 10px;
  padding: 2px 8px;
  max-width: 100%;
}
.banner-form-input:focus {
  border: 0;
  outline: 0;
  border-bottom: 1px solid var(--theme-primary-light);
}
.form-control:focus {
  border-color: var(--theme-primary);
  box-shadow: 0 0 0 0.25rem var(--theme-primary-shadow);
}
fieldset {
  border: none !important;
  outline: none !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root input {
  padding: 5.5px 0 !important;
}
.css-1uvydh2 {
  padding: 5.5px 0 !important;
}

.remove-calendar-icon[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.form-select:focus {
  border-color: var(--theme-primary);
  box-shadow: 0 0 0 0.25rem var(--theme-primary-shadow);
}
.form-select option:focus {
  background-color: var(--theme-primary) !important;
}
.form-select option:hover {
  background-color: var(--theme-primary) !important;
}
.form-select option:active {
  background-color: var(--theme-primary) !important;
}
@media (max-width: 768px) {
  .col-reverse-sm {
    flex-direction: column-reverse;
  }
}
@media (max-width: 540px) {
  .col-reverse-xs {
    flex-direction: column-reverse;
  }
  .mx-auto-sm {
    margin: 0 auto;
  }
}
@media (max-width: 840px) {
  .d-none-md {
    display: none;
  }
}

.border-dashed {
  border: 1px dashed var(--dark2);
}
.border-doubled-theme {
  border-bottom: 4px double var(--theme-primary-shadow);
}
.border-primary-theme {
  border-color: var(--theme-primary) !important;
}

.custom-card-shadow {
  --tw-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 20;
  height: 70px;
}

.flight-sidebar {
  position: sticky;
  max-height: calc(100vh - 70px);
  overflow: auto;
  top: 70px;
  width: 315px;
}

.flight-body {
  height: 100%;
  width: calc(100% - 315px);
  overflow: auto;
}

.flight-ticket-img {
  width: 80px;
  height: 60px;
  object-fit: contain;
  object-position: center;
}
.flight-ticket-img-lg {
  max-height: 80px;
  width: 220px;
  object-fit: contain;
  object-position: center center;
}

@media (min-width: 1050px) {
  .flight-page-header-lg {
    display: block !important;
  }
  .flight-page-header-sm {
    display: none !important;
  }
}
@media (max-width: 1050px) {
  .flight-page-header-lg {
    display: none !important;
  }
  .flight-page-header-sm {
    display: block !important;
  }
}
@media (min-width: 800px) {
  .flight-page-sidebar-lg {
    display: block !important;
  }
  .flight-page-sidebar-sm {
    display: none !important;
  }
}
@media (max-width: 800px) {
  .flight-page-sidebar-lg {
    display: none !important;
  }
  .flight-body {
    width: 100%;
  }
  .flight-page-sidebar-sm {
    display: block !important;
  }
}
.table-w-200p {
  min-width: 200px;
  max-width: 200px;
}
.mw-140p {
  max-width: 140px;
}

/* Checkout Page */
.checkout-page-flight-logo {
  width: 120px;
  height: 60px;
  object-fit: contain;
  object-position: center;
}

.accordion-button:not(.collapsed) {
  color: var(--theme-primary-dark);
  background-color: var(--theme-primary-shadow);
}
.accordion-button:not(.collapsed)::after {
  filter: hue-rotate(250deg);
}
.form-check-input:checked {
  background-color: var(--theme-primary);
  border-color: var(--theme-primary-shadow);
}
.dropdown-item:active {
  background-color: var(--theme-primary);
}

/* Deal */
.offer_img {
  max-height: 240px;
  max-width: 240px;
  object-fit: contain;
  object-position: center;
}

/* Single Blog */
.single-blog-header {
  height: 450px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  mask-image: linear-gradient(to bottom, #000 20%, transparent 90%);
}
.single-blog-article {
  margin-top: -200px;
  padding-top: 200px;
  padding-bottom: 100px;
  z-index: 1;
}
.single-blog-title {
  font-size: 3rem;
  line-height: 1.2;
}
.single-blog-image {
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/* Chip */
.active_price_chip {
  background: var(--theme-primary-shadow);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    var(--theme-primary-shadow) 0px 0px 0px 1px;
  transition: all 0.4s linear;
  padding: 0 0 0 6px;
  display: flex;
}
.clear_active_price_chip {
  background: var(--light);
  border-left: 1px solid var(--light3);
  padding: 0 6px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: grid;
  place-items: center;
  transition: all 0.4s linear;
  cursor: pointer;
}
.clear_active_price_chip:hover {
  background: var(--light3);
}
.clear_active_price_chip:active {
  box-shadow: var(--theme-primary-shadow) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.input-with-icon {
  position: relative;
}

.input-with-icon svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Card Type */
.card_type .form-control {
  background-color: transparent;
  color: #fff;
  border: none;
  font-style: italic;
  font-size: 20px;
}
.card_type .form-group {
  margin-bottom: 0px;
}
.card_type .form-group select:focus {
  border: none;
  box-shadow: none;
}

/* Card Cvv */
.card_cvv input.p-inputtext.p-component {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #fff;
  width: 70%;
  float: right;
  text-align: right;
  height: 30px;
}
.card_cvv input.p-inputtext.p-component:enabled:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.card_cvv input.p-inputtext.p-component::placeholder {
  color: #fff;
}

.card_no h5 {
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
}

/* Card No */
.card_no input.p-inputtext.p-component {
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #fff;
  text-align: center;
  width: 70%;
}
.card_no input.p-inputtext.p-component:enabled:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.card_no input.p-inputtext.p-component::placeholder {
  color: #fff;
}

/* Card Holder */
.card_holder input.p-inputtext.p-component {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 16px;
  padding-left: 0px;
  width: 100%;
  height: 30px;
}
.card_holder input.p-inputtext.p-component:enabled:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.card_holder input.p-inputtext.p-component::placeholder {
  color: #fff;
}

.card_holder,
.card_expiry p {
  color: #fff;
  font-size: 14px;
  padding-left: 15px;
  margin-bottom: 5px;
}

/* Card Expiry  */
.card_expiry input.p-inputtext.p-component {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #fff;
  padding-left: 15px;
  width: 70%;
  height: 30px;
}
.card_expiry input.p-inputtext.p-component:enabled:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.card_expiry input.p-inputtext.p-component::placeholder {
  color: #fff;
}

.dropdown-toggle-span::after {
  display: inline-block;
  margin-left: 8rem;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

/* Custom Modal */
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* OTP BOX */
.otp-box {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.otp-input {
  width: 20%;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  outline: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  padding: 8px;
  font-size: 1.2rem;
  text-align: center;
}
.otp-input:focus {
  box-shadow: rgba(50, 205, 50, 0.5) 0px 0px 0px 3px;
}
.otp-input::-webkit-inner-spin-button {
  display: none;
}

.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;;
}
