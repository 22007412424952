.loader-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 88px);
}

/* Default Loader */
.default-loader-scene {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 300px;
  height: 200px;
}
.default-loader-plane,
.default-loader-cloud {
  position: absolute;
}
/* Plane Animation */
.default-loader-plane {
  animation-duration: 1s;
  animation-name: default-loader-anim-plane;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  display: block;
  margin: 0 auto;
  transform: translateY(80px);
  left: 30%;
  filter: hue-rotate(275deg);
}

@keyframes default-loader-anim-plane {
  to {
    transform: translateY(95px);
  }
}

/* Cloud Animation */
@keyframes default-loader-fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes default-loader-move {
  from {
    left: 200px;
  }
  to {
    left: 0px;
  }
}

.default-loader-cloud {
  animation-duration: 10s;
  animation-name: default-loader-move, default-loader-fade;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: both;
  display: block;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iY2xvdWQiIHZpZXdCb3g9IjAgMCA1MiA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiAgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI1MnB4IiBoZWlnaHQ9IjQwcHgiPgoJPGRlZnM+CgkJPGZpbHRlciBpZD0iZjEiIHg9Ii0xMDAlIiB5PSItMTAwJSIgd2lkdGg9IjMwMCUiIGhlaWdodD0iMzAwJSI+IAoJCQk8ZmVPZmZzZXQgcmVzdWx0PSJvdXQiIGluPSJTb3VyY2VHcmFwaGljIiBkeD0iMCIgZHk9IjEiLz4KCQkJPGZlQ29sb3JNYXRyaXggcmVzdWx0PSJvdXQiIGluPSJvdXQiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgIDAgMCAwIDAgMCAgMCAwIDAgMCAwICAwIDAgMCAwLjQgMCIvPgoJCQk8ZmVHYXVzc2lhbkJsdXIgcmVzdWx0PSJvdXQiIGluPSJvdXQiIHN0ZERldmlhdGlvbj0iMiIvPgoJCQk8ZmVCbGVuZCBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJvdXQiIG1vZGU9Im5vcm1hbCIgcmVzdWx0PSJkcCIvPgoJCTwvZmlsdGVyPgoJPC9kZWZzPiAKCTxwYXRoIGlkPSJmZy1jbG91ZCIgZmlsdGVyPSJ1cmwoI2YxKSIgZD0iTTYuMyAzNS4xQzQuNyAzNC4yLTAuNCAzMi4zIDEuNCAyNSAzLjEgMTguMSA4LjcgMTkuNSA4LjcgMTkuNSA4LjcgMTkuNSAzLjIgMTQuMSAxMC40IDYuOCAxNi45IDAuMiAyMy4xIDQuNiAyMy4xIDQuNiAyMy4xIDQuNiAzMC0xLjcgMzUuMiAyLjQgNDQuNiA5LjcgNDIuOCAyNS4zIDQyLjggMjUuMyA0Mi44IDI1LjMgNDggMjIuNiA0OS44IDI4LjYgNTEgMzIuNyA0NiAzNS44IDQyLjggMzYuNyAzOS43IDM3LjUgOC45IDM2LjYgNi4zIDM1LjFaIiBzdHJva2U9IiNjY2NjY2MiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0iI2ZmZmZmZiIvPgo8L3N2Zz4=);
  height: 40px;
  width: 53px;
  margin: 0 auto;
}
.default-loader-cloud--small {
  animation-duration: 6s;
  top: 65px;
  transform: scaleX(0.5) scaleY(0.5);
}
.default-loader-cloud--medium {
  animation-duration: 5s;
  animation-delay: 1s;
  top: 95px;
  transform: scaleX(0.7) scaleY(0.7);
}
.default-loader-cloud--large {
  animation-duration: 4.5s;
  animation-delay: 2.5s;
  top: 95px;
  transform: scaleX(0.8) scaleY(0.8);
}

/* Flights Page Loader */
.loader-earth {
  text-align: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 245px;
  margin: 0 auto;
  animation: earth-animation-display 2s ease-in-out;
}

.earth-wrapper {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  padding-top: 44px;
}

.earth {
  width: 160px;
  height: 160px;
  background: url("https://zupimages.net/up/19/34/6vlb.gif");
  border-radius: 100%;
  background-size: 340px;
  animation: earthAnim 12s infinite linear;
  margin: 0 auto;
  border: 1px solid #cdd1d3;
  filter: hue-rotate(275deg);
}

.departure_city,
.arrival_city {
  font-size: 34px;
  font-weight: bold;
  opacity: 0.8;
  color: var(--theme-primary-dark);
  margin: 0 auto;
  overflow: hidden;

  animation: city_zoom 2s ease-in-out;
  text-align: center;
}
@media (max-width: 567px) {
  .departure_city,
  .arrival_city {
    font-size: 22px;
  }
  .loader-earth {
    scale: 0.8;
  }
}

.plane {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  animation: plane-animation-display 4s ease-in-out;
}

.plane-img {
  -webkit-animation: spin 2.5s linear infinite;
  -moz-animation: spin 2.5s linear infinite;
  animation: spin 2.5s linear infinite;
}

@keyframes earth-animation-display {
  0% {
    transform: scale(0, 0);
    visibility: hidden;
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes city_zoom {
  0% {
    transform: scale(0, 0);
    visibility: hidden;
  }
  60% {
    transform: scale(0, 0);
    visibility: hidden;
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes plane-animation-display {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes earthAnim {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -340px;
  }
}

.react-tooltip-lite {
  background: #333;
  color: white;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}